import dayjs from 'dayjs';
import moment from 'moment';
import _ from 'lodash';
import { Const } from '@/const';
import { PaginatedList } from '@/types/paginated-list';
import { FormValidatable, FormValidator } from '@/models/validate-helper';
import { BaggageUtil, DateUtil, Util } from '@/util';
import { ArrivalDataTypeEnum, PeriodicContractBaggageTimeTypeEnum } from '@/models/vo/periodic-contract';
import { TmpBaggageLocation, TmpBaggageLocationValue } from '@/models/baggage';
import { BaggageCategoryEnum, BaggageCategoryEnumCode } from '@/enums/baggage-category.enum';
import { BaggageShapeEnum, BaggageShapeEnumCode } from '@/enums/baggage-shape.enum';
import { Enum } from '@/types/enum';
import { BaggageHandlingTypeEnum, BaggageHandlingTypeEnumCode } from '@/enums/baggage-handling-type.enum';
import { NegotiationTypeEnum, NegotiationTypeEnumCode } from '@/enums/negotiation-type.enum';
import { BaggageTemperatureZoneEnum } from '@/enums/baggage-temperature-zone.enum';
import { BaggageFreightValue } from '@/models/vo/baggage-freight';
import { TruckWeightEnum } from '@/enums/truck-weight.enum';
import { TruckModelEnum } from '@/enums/truck-model.enum';

export interface PeriodicContractSearchForm {
    pageNo: number;
    pageSize: number;
}

export class PeriodicContractSearchFormModel implements PeriodicContractSearchForm {
    pageNo: number;
    pageSize: number;

    constructor(param: Partial<PeriodicContractSearchForm> | null = null) {
        this.pageNo = param?.pageNo ?? 1;
        this.pageSize = param?.pageSize ?? Const.DEFAULT_PAGE_SIZE;
    }
}

export interface PeriodicContractList extends PaginatedList {
    data: PeriodicContract[];
}

interface ScheduledWeekOfDay {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
}

export interface PeriodicContract {
    id: number;
    companyId: number;
    validFrom: string;
    validTo: string;
    description: string;
    schedule: ScheduledWeekOfDay;
    lastExecutedStartTm: string;
    lastExecutedEndTm: string;
    baggage: PeriodicContractBaggage
}

export interface PeriodicContractBaggage {
    circleId: number;
    departureTime?: string;
    departureTimeType: PeriodicContractBaggageTimeTypeEnum;
    departureLocation: TmpBaggageLocation;
    loadingTimeNote?: string;
    arrivalDateType: ArrivalDataTypeEnum;
    arrivalTime?: string;
    arrivalTimeType: PeriodicContractBaggageTimeTypeEnum;
    arrivalLocation: TmpBaggageLocation;
    unloadingTimeNote?: string;
    category: { code?: BaggageCategoryEnumCode };
    type: string;
    shape?: Enum<BaggageShapeEnumCode>;
    temperatureZone: { code?: string };
    paletteCount?: number;
    paletteHeight?: number;
    paletteWidth?: number;
    totalCount?: number;
    totalVolume?: number;
    totalWeight?: number;
    loading?: Enum<BaggageHandlingTypeEnumCode>;
    unloading?: Enum<BaggageHandlingTypeEnumCode>;
    truckWeight: TruckWeightEnum;
    truckModel: TruckModelEnum;
    truckHeight?: { code: string };
    truckWidth?: { code: string };
    largeTruckFlg?: boolean;
    truckEquipment?: string;
    share: boolean;
    express: boolean;
    freight?: number;
    highwayFareFlg: boolean;
    staffName: string;
    description?: string
    underNegotiation: boolean;
    negotiationType: Enum<NegotiationTypeEnumCode>;
    shipperName?: string;
    labelText?: string;
    labelColor?: string;
}

export class PeriodicContractModel {
    id: number;
    companyId: number;
    validFrom: string;
    validTo: string;
    description: string;
    schedule: ScheduledWeekOfDay;
    lastExecutedStartTm: string;
    lastExecutedEndTm: string;
    baggage: PeriodicContractBaggage;

    constructor(param: PeriodicContract) {
        this.id = param.id;
        this.companyId = param.companyId;
        this.validFrom = param.validFrom;
        this.validTo = param.validTo;
        this.description = param.description;
        this.schedule = param.schedule;
        this.lastExecutedStartTm = param.lastExecutedStartTm;
        this.lastExecutedEndTm = param.lastExecutedEndTm;
        this.baggage = param.baggage;
    }

    validTerm(): string {
        const validFrom = DateUtil.parseDateText(this.validFrom).format(Const.DEFAULT_VIEW_DATE_FORMAT);
        const validTo = DateUtil.parseDateText(this.validTo).format(Const.DEFAULT_VIEW_DATE_FORMAT);
        return `${ validFrom } ~ ${ validTo }`;
    }

    get dayOfWeek(): string {
        let result = '';
        if (this.schedule.monday) {
            result += '月';
        }
        if (this.schedule.tuesday) {
            result += '火';
        }
        if (this.schedule.wednesday) {
            result += '水';
        }
        if (this.schedule.thursday) {
            result += '木';
        }
        if (this.schedule.friday) {
            result += '金';
        }
        if (this.schedule.saturday) {
            result += '土';
        }
        if (this.schedule.sunday) {
            result += '日';
        }

        return result;
    }

    get lastExecutedTerm(): string {
        if (this.lastExecutedStartTm && this.lastExecutedEndTm) {
            const from = DateUtil.parseDateText(this.lastExecutedStartTm).format(Const.DEFAULT_VIEW_DATE_FORMAT);
            const to = DateUtil.parseDateText(this.lastExecutedEndTm).format(Const.DEFAULT_VIEW_DATE_FORMAT);
            return `${ from } ~ ${ to }`;
        }
        return '';
    }

    get departureShort(): string {
        const { prefecture, city } = this.baggage.departureLocation;
        if (prefecture && city) {
            return `${ prefecture.label } ${ city }`;
        }
        return '';
    }

    get departureFull(): string {
        const { prefecture, city, address } = this.baggage.departureLocation;
        if (prefecture && city) {
            return `${ prefecture.label } ${ city } ${ address ?? '' }`;
        }

        return '';
    }

    get arrivalShort(): string {
        const { prefecture, city } = this.baggage.arrivalLocation;
        if (prefecture && city) {
            return `${ prefecture.label } ${ city }`;
        }
        return '';
    }

    get arrivalFull(): string {
        const { prefecture, city, address } = this.baggage.arrivalLocation;
        if (prefecture && city) {
            return `${ prefecture.label } ${ city } ${ address ?? '' }`;
        }
        return '';
    }

    get truckType(): string {
        if (this.baggage.truckWeight && this.baggage.truckModel) {
            return `${ this.baggage.truckWeight.label } / ${ this.baggage.truckModel.label }`;
        }

        return '';
    }

    get freightText(): string {
        if (this.baggage.freight) {
            return `${ this.baggage.freight.toLocaleString() }円`;
        }

        return '';
    }
}

/**
 *  定期契約登録フォーム
 */
export class PeriodicContractForm {
    validFrom: string;
    validTo: string;
    // 定期契約自体に記載する社内メモ
    description: string;
    schedule: ScheduledWeekOfDay;
    circleId: number | undefined;
    // 発情報
    departureTime?: string;
    departureTimeType?: PeriodicContractBaggageTimeTypeEnum;
    loadingTimeNote?: string;
    departurePref: { code?: string };
    departureCity?: string;
    departureAddress?: string;
    departureCompanyPlaceId?: number;
    // 着情報
    arrivalDateType: ArrivalDataTypeEnum = ArrivalDataTypeEnum.TOMMOROW;
    arrivalTime?: string;
    arrivalTimeType: PeriodicContractBaggageTimeTypeEnum = PeriodicContractBaggageTimeTypeEnum.NO_DESIGNATION;
    arrivalPref: { code?: string };
    arrivalCity?: string;
    arrivalAddress?: string;
    arrivalCompanyPlaceId?: number;
    unloadingTimeNote?: string;
    // 引っ越し
    category: { code?: BaggageCategoryEnumCode };
    // 荷種
    type: string;
    // 荷姿
    shape?: Enum<BaggageShapeEnumCode>;
    // パレット情報
    paletteCount?: string;
    paletteHeight?: string;
    paletteWidth?: string;
    // その他情報
    totalCount?: string;
    totalVolume?: string;
    // 総重量
    totalWeight?: string;
    // ドライバー作業
    loading?: Enum<BaggageHandlingTypeEnumCode>;
    unloading?: Enum<BaggageHandlingTypeEnumCode>;
    // トラック情報
    truckWeight: { code?: string };
    truckModel: { code?: string };
    truckHeight?: { code: string };
    truckWidth?: { code: string };
    largeTruckFlg?: boolean;
    truckEquipment?: string;
    // フラグ情報
    share: boolean;
    express: boolean;
    // 運賃
    freight?: string;
    highwayFareFlg?: boolean;
    // 担当者
    staffName: string;
    // 荷物の備考
    baggageDescription?: string;
    // 商談中
    underNegotiation: boolean;
    // 連絡方法
    negotiationType?: Enum<NegotiationTypeEnumCode>;
    // 温度帯
    temperatureZone: { code?: string };
    // 荷主名
    shipperName: string | undefined;
    // ラベルのテキスト
    labelText: string | undefined;
    // ラベルの色
    labelColor: string | undefined;

    constructor(param: Partial<PeriodicContractForm> | null = null) {
        this.validFrom = param?.validFrom ?? '';
        this.validTo = param?.validTo ?? '';
        this.description = param?.description ?? '';
        this.schedule = param?.schedule ?? {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
        };
        this.departureTime = param?.departureTime ?? undefined;
        this.departureTimeType = param?.departureTimeType ?? PeriodicContractBaggageTimeTypeEnum.DESIGNATION;
        this.departurePref = param?.departurePref ?? {};
        this.departureCity = param?.departureCity ?? '';
        this.departureCompanyPlaceId = param?.departureCompanyPlaceId;
        this.departureAddress = param?.departureAddress ?? '';
        this.loadingTimeNote = param?.loadingTimeNote;
        this.arrivalDateType = param?.arrivalDateType ?? ArrivalDataTypeEnum.TOMMOROW;
        this.arrivalTime = param?.arrivalTime ?? undefined;
        this.arrivalTimeType = param?.arrivalTimeType ?? PeriodicContractBaggageTimeTypeEnum.DESIGNATION;
        this.arrivalPref = param?.arrivalPref ?? {};
        this.arrivalCity = param?.arrivalCity ?? '';
        this.arrivalAddress = param?.arrivalAddress ?? '';
        this.arrivalCompanyPlaceId = param?.arrivalCompanyPlaceId;
        this.unloadingTimeNote = param?.unloadingTimeNote;
        // 引っ越し
        this.category = param?.category ?? { code: 'BC1' };
        // 荷種
        this.type = param?.type ?? '';
        // 荷姿
        this.shape = param?.shape;
        // パレット情報
        this.paletteCount = param?.paletteCount ?? '';
        this.paletteHeight = param?.paletteHeight ?? '';
        this.paletteWidth = param?.paletteWidth ?? '';
        // その他情報
        this.totalCount = param?.totalCount ?? '';
        this.totalVolume = param?.totalVolume ?? '';
        // 総重量
        this.totalWeight = param?.totalWeight ?? '';
        // ドライバー作業
        this.loading = param?.loading;
        this.unloading = param?.unloading;
        // トラック情報
        this.truckWeight = param?.truckWeight ?? { code: 'TW19' };
        this.truckModel = param?.truckModel ?? { code: 'TM17' };
        this.truckHeight = param?.truckHeight;
        this.truckWidth = param?.truckWidth;
        this.largeTruckFlg = param?.largeTruckFlg;
        this.truckEquipment = param?.truckEquipment;
        // フラグ情報
        this.share = param?.share ?? false;
        this.express = param?.express ?? false;
        // 運賃
        this.freight = param?.freight ?? '0';
        this.highwayFareFlg = param?.highwayFareFlg ?? false;
        // 担当者
        this.staffName = param?.staffName ?? '';
        // 荷物備考
        this.baggageDescription = param?.baggageDescription ?? '';
        // 商談中
        this.underNegotiation = param?.underNegotiation ?? false;
        // 連絡方法
        this.negotiationType = param?.negotiationType;
        // 温度帯
        this.temperatureZone = param?.temperatureZone ?? { code: BaggageTemperatureZoneEnum.Ambient.code };
        // 荷主名
        this.shipperName = param?.shipperName;
        // ラベルのテキスト
        this.labelText = param?.labelText;
        // ラベルの色
        this.labelColor = param?.labelColor;
    }
}

/**
 *  定期契約登録フォームモデル
 */
export class PeriodicContractFormModel
    extends PeriodicContractForm
    implements FormValidatable<PeriodicContractForm> {

    departureLocation: TmpBaggageLocationValue;
    arrivalLocation: TmpBaggageLocationValue;
    previousType: string;
    negotiate: boolean;

    constructor(param: Partial<PeriodicContractFormModel> | null = null) {
        super(param);
        this.departureLocation = new TmpBaggageLocationValue(
            param?.departureLocation?.value ? param.departureLocation.value : {}
        );
        this.arrivalLocation = new TmpBaggageLocationValue(
            param?.arrivalLocation?.value ? param.arrivalLocation.value : {}
        );
        this.previousType = param?.previousType ?? '';
        this.negotiate = !this.freight;
        this.negotiationType = param?.negotiationType ?? NegotiationTypeEnum.Online;
    }

    /**
     * FIXME ant-design-vueをupgradeしたらmomentからdayjsに変更する
     */
    get validTerm(): moment.Moment[] | string[] {
        if (_.isEmpty(this.validFrom) || _.isEmpty(this.validTo)) {
            return ['', ''];
        }

        return [
            moment(this.validFrom),
            moment(this.validTo),
        ];
    }

    set validTerm(value: moment.Moment[] | string[]) {
        if (!value) {
            this.validFrom = this.validTo = '';
            return;
        }
        // @ts-ignore
        const value1 = PeriodicContractFormModel.ensureDayJs(value[0]);
        // @ts-ignore
        const value2 = PeriodicContractFormModel.ensureDayJs(value[1]);

        // @ts-ignore
        this.validFrom = DateUtil.formatDateTime(value1.startOf('day'));
        // @ts-ignore
        this.validTo = DateUtil.formatDateTime(value2.endOf('day'));
    }

    get descriptionText(): string {
        return this.description ?? '';
    }

    set descriptionText(value: string) {
        this.description = value;
    }

    get departureTimeDisabled(): boolean {
        switch (this.departureTimeType?.code) {
            case PeriodicContractBaggageTimeTypeEnum.DESIGNATION.code:
                return false;
            default:
                return true;
        }
    }

    get arrivalTimeDisabled(): boolean {
        switch (this.arrivalTimeType.code) {
            case PeriodicContractBaggageTimeTypeEnum.DESIGNATION.code:
                return false;
            default:
                return true;
        }
    }

    complementDepartureTime(): void {
        if (this.departureTime) {
            this.departureTime = DateUtil.complementTime(this.departureTime);
        }
    }

    completeArrivalTime(): void {
        if (this.arrivalTime) {
            this.arrivalTime = DateUtil.complementTime(this.arrivalTime);
        }
    }

    get baggageType(): string | undefined {
        return this.type;
    }

    set baggageType(value: string | undefined) {
        this.type = value ?? '';
        // 引っ越しという単語が含まれている場合は引っ越し案件に強制的に設定
        const isRelocate = BaggageUtil.includesRelocateWord(value);
        if (isRelocate) {
            this.category = { code: BaggageCategoryEnum.Relocation.code };
        } else if (this.category.code === BaggageCategoryEnum.Relocation.code) {
            this.category = { code: BaggageCategoryEnum.Normal.code };
        }
    }

    get shapeCode(): BaggageShapeEnumCode | undefined {
        return this.shape?.code as BaggageShapeEnumCode;
    }

    set shapeCode(value: BaggageShapeEnumCode | undefined) {
        this.shape = BaggageShapeEnum.valueOf(value);
        // 荷姿の変更時の荷種の補完
        switch (this.shape?.code) {
            case BaggageShapeEnum.Palette.code:
                this.previousType = this.type.trim();
                this.type = 'パレット';
                break;
            case BaggageShapeEnum.Other.code:
                this.type = this.previousType;
                break;
            default:
                break;
        }
    }

    /**
     * 荷姿が指定されているか否か
     */
    get hasShape(): boolean {
        return !!this.shape;
    }

    /**
     * 荷姿がパレットであるか否か
     */
    get isShapePalette(): boolean {
        return this.shape?.code === BaggageShapeEnum.Palette.code;
    }

    /**
     * 荷姿がその他であるか否か
     */
    get isShapeOther(): boolean {
        return this.shape?.code === BaggageShapeEnum.Other.code;
    }

    get paletteCountText(): string {
        return this.paletteCount ?? '';
    }

    set paletteCountText(value: string) {
        this.paletteCount = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get paletteHeightText(): string {
        return this.paletteHeight ?? '';
    }

    set paletteHeightText(value: string) {
        this.paletteHeight = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get paletteWidthText(): string {
        return this.paletteWidth ?? '';
    }

    set paletteWidthText(value: string) {
        this.paletteWidth = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    // Note: 本来不要なのだけど、antdのバリデーション処理をするために定義している
    // TODO: 要リファクタリング
    get paletteSize(): { paletteHeight: string | undefined, paletteWidth: string | undefined } {
        return {
            paletteHeight: this.paletteHeight,
            paletteWidth: this.paletteWidth,
        };
    }

    get totalCountText(): string {
        return this.totalCount ?? '';
    }

    set totalCountText(value: string) {
        this.totalCount = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get totalVolumeText(): string {
        return this.totalVolume ?? '';
    }

    set totalVolumeText(value: string) {
        this.totalVolume = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get totalWeightText(): string {
        return this.totalWeight ?? '';
    }

    set totalWeightText(value: string) {
        this.totalWeight = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get loadingCode(): string | undefined {
        return this.loading?.code;
    }

    set loadingCode(value: string | undefined) {
        this.loading = BaggageHandlingTypeEnum.valueOf(value);
    }

    get unloadingCode(): string | undefined {
        return this.unloading?.code;
    }

    set unloadingCode(value: string | undefined) {
        this.unloading = BaggageHandlingTypeEnum.valueOf(value);
    }

    get truckEquipmentText(): string {
        return this.truckEquipment ?? '';
    }

    set truckEquipmentText(value: string) {
        this.truckEquipment = value;
    }

    get temperatureZoneCode(): string {
        return this.temperatureZone.code ?? '';
    }

    set temperatureZoneCode(value: string) {
        this.temperatureZone = { code: value };
    }

    get truckWeightCode(): string {
        return this.truckWeight.code ?? '';
    }

    set truckWeightCode(value: string) {
        this.truckWeight = { code: value };
    }

    get truckModelCode(): string {
        return this.truckModel.code ?? '';
    }

    set truckModelCode(value: string) {
        this.truckModel = { code: value };
    }

    /**
     * 車両指定に関係する項目のいずれかが登録されているか
     */
    get hasAnySpecifiedTruck(): boolean {
        return !_.isNil(this.truckHeight) || !_.isNil(this.truckWidth) || !_.isNil(this.largeTruckFlg);
    }

    clearSpecifiedTruck(): void {
        this.truckHeight = undefined;
        this.truckWidth = undefined;
        this.largeTruckFlg = undefined;
    }

    get truckHeightCode(): string | undefined {
        return this.truckHeight?.code;
    }

    set truckHeightCode(value: string | undefined) {
        this.truckHeight = value ? { code: value } : undefined;
    }

    get truckWidthCode(): string | undefined {
        return this.truckWidth?.code;
    }

    set truckWidthCode(value: string | undefined) {
        this.truckWidth = value ? { code: value } : undefined;
    }

    get largeTruckFlgCode(): string | undefined {
        if (this.largeTruckFlg == true) return 'true';
        if (this.largeTruckFlg == false) return 'false';
        return undefined;
    }

    set largeTruckFlgCode(value: string | undefined) {
        const toBoolean = (value: string | undefined) => {
            if (value?.toLowerCase() === 'true') return true;
            if (value?.toLowerCase() === 'false') return false;
            return undefined;
        };
        this.largeTruckFlg = toBoolean(value);
    }

    get baggageFreight(): BaggageFreightValue {
        return new BaggageFreightValue(!this.negotiate ? Util.toNumber(this.freight ?? '0') : undefined);
    }

    set baggageFreight(value: BaggageFreightValue) {
        this.negotiate = value.isNegotiate;
        this.freight = value.value?.toString() ?? '';
    }

    get baggageDescriptionText(): string {
        return this.baggageDescription ?? '';
    }

    set baggageDescriptionText(value: string) {
        this.baggageDescription = value;
    }

    get relocate(): boolean {
        return this.category.code == BaggageCategoryEnum.Relocation.code;
    }

    set relocate(value: boolean) {
        this.category.code =
            value || BaggageUtil.includesRelocateWord(this.type)
                ? BaggageCategoryEnum.Relocation.code
                : BaggageCategoryEnum.Normal.code;
    }

    get label(): { labelText?: string, labelColor?: string } {
        return {
            labelText: this.labelText,
            labelColor: this.labelColor
        };
    }

    set label(value: { labelText?: string, labelColor?: string }) {
        this.labelText = value.labelText;
        this.labelColor = value.labelColor;
    }

    public normalize(): void {
        this.departureCity = this.departureCity?.trim();
        this.departureAddress = this.departureAddress?.trim();
        this.arrivalCity = this.arrivalCity?.trim();
        this.arrivalAddress = this.arrivalAddress?.trim();
        this.baggageType = this.baggageType?.trim();
        this.truckEquipmentText = this.truckEquipmentText.trim();
        this.baggageDescriptionText = this.baggageDescriptionText.trim();
        this.descriptionText = this.descriptionText.trim();
        this.staffName = this.staffName?.trim();
        this.shipperName = this.shipperName?.trim();
        this.labelText = this.labelText?.trim();
    }

    /**
     * {@link PeriodicContractForm}を生成します
     */
    toForm(): PeriodicContractForm {
        return new PeriodicContractForm(this);
    }

    /**
     * {@link PeriodicContractApiForm}を生成します
     */
    toApiForm(): PeriodicContractApiForm {
        const form = new PeriodicContractApiForm(this);

        // 要相談なら運賃をクリア
        form.baggage.freight = this.negotiate ? '' : form.baggage.freight;
        // 荷姿：パレットなら個数、体積をクリア
        if (this.isShapePalette) {
            form.baggage.totalCount = undefined;
            form.baggage.totalVolume = undefined;
        }
        // 荷姿：その他ならパレット枚数（目安）、サイズをクリア
        if (this.isShapeOther) {
            form.baggage.paletteCount = undefined;
            form.baggage.paletteHeight = undefined;
            form.baggage.paletteWidth = undefined;
        }

        return form;
    }

    validateValidTerm(callback: (message?: string) => void): void {
        if (_.isEmpty(this.validFrom) || _.isEmpty(this.validTo)) {
            callback('契約期間を選択してください。');
        }
        callback();
    }

    validateTime(callback: (message?: string) => void, type?: PeriodicContractBaggageTimeTypeEnum, value?: string,): void {
        if (!type) {
            return callback('入力してください');
        }

        if (PeriodicContractBaggageTimeTypeEnum.valuesWithoutTimeInput.map(each => each.code).includes(type.code)) {
            callback();
        }

        if (!value) {
            return callback('入力してください');
        }

        const regexp = new RegExp(/^[0-9]{1,2}:[0-9]{1,2}$/);
        if (!regexp.test(value)) {
            callback('フォーマットが正しくありません。');
        }

        callback();
    }

    /**
     * 当日積み卸しの時間を検証します。
     * 仕様を整理したものはこちら
     * https://github.com/trabox-inc/junction/issues/245#issuecomment-2458873738
     */
    validateSameDayDepartureArrivalTime(callback: (message?: string) => void): void {
        if (this.arrivalDateType.code !== ArrivalDataTypeEnum.TODAY.code) {
            callback();
            return;
        }

        // 当日午後積みの場合、午前卸しはエラーにする
        if (this.departureTimeType?.code === PeriodicContractBaggageTimeTypeEnum.PM.code) {
            if (this.arrivalTimeType.code === PeriodicContractBaggageTimeTypeEnum.AM.code) {
                callback('積み卸し時間が不正です。');
                return;
            }
            if (this.arrivalTimeType.code === PeriodicContractBaggageTimeTypeEnum.DESIGNATION.code) {
                if (!this.arrivalTime) {
                    callback();
                    return;
                }

                const [hours, minutes] = this.arrivalTime.split(':').map(Number);
                const arrivalTime = DateUtil.now().hour(hours).minute(minutes).second(0).millisecond(0);
                if (arrivalTime.hour() < 12) {
                    callback('積み卸し時間が不正です。');
                    return;
                }
            }
        }

        // 当日積みを時間指定する場合、卸し時間の関係をチェックする
        if (this.departureTimeType?.code === PeriodicContractBaggageTimeTypeEnum.DESIGNATION.code) {
            if (!this.departureTime) {
                callback();
                return;
            }
            const [hours, minutes] = this.departureTime.split(':').map(Number);
            const departureTime = DateUtil.now().hour(hours).minute(minutes).second(0).millisecond(0);

            // 当日午前卸しの場合、午後積みはエラー
            if (this.arrivalTimeType.code === PeriodicContractBaggageTimeTypeEnum.AM.code) {
                if (departureTime.hour() >= 12) {
                    callback('積み卸し時間が不正です。');
                    return;
                }
            }

            // 積み下ろし時間の逆転チェック
            if (this.arrivalTimeType.code == PeriodicContractBaggageTimeTypeEnum.DESIGNATION.code) {
                if (!this.arrivalTime) {
                    callback();
                    return;
                }

                const [hours, minutes] = this.arrivalTime.split(':').map(Number);
                const arrivalTime = DateUtil.now().hour(hours).minute(minutes).second(0).millisecond(0);
                if (departureTime.isAfter(arrivalTime)) {
                    callback('積み卸し時間が不正です。');
                    return;
                }
            }
        }

        callback();
    }

    validateLocation(callback: (message?: string) => void, value?: TmpBaggageLocation): void {
        if (!value) {
            return callback('入力してください');
        }

        if (value.isCompanyPlace) {
            if (value.companyPlace?.id) {
                return callback();
            }
            return callback('地点名を選択してください。');
        }

        if (!value.prefecture?.code) {
            callback('都道府県を選択してください。');
        }

        if (!value.city) {
            callback('市区町村を入力してください。');
        } else if (value.city.length > 200) {
            callback('市区町村は200文字以内で入力してください。');
        }

        if (value.address && value.address.length > 200) {
            callback('番地・建物は200文字以内で入力してください。');
        }

        callback();
    }

    validatePaletteSize(callback: (message?: string) => void): void {
        if (this.paletteHeight) {
            if (this.paletteHeight.length > Const.MAX_PALETTE_SIZE_DIGITS) {
                callback(`パレットサイズ(縦)は最大${ Const.MAX_PALETTE_SIZE_DIGITS }桁で入力してください。`);
                return;
            }

            if (!Util.isNumeric(this.paletteHeight)) {
                callback(`パレットサイズ(縦)は数字で入力してください。`);
                return;
            }
        }

        if (this.paletteWidth) {
            if (this.paletteWidth.length > Const.MAX_PALETTE_SIZE_DIGITS) {
                callback(`パレットサイズ(横)は最大${ Const.MAX_PALETTE_SIZE_DIGITS }桁で入力してください。`);
                return;
            }

            if (!Util.isNumeric(this.paletteWidth)) {
                callback(`パレットサイズ(横)は数字で入力してください。`);
                return;
            }
        }

        callback();
    }

    validateLabel(callback: (message?: string) => void): void {
        if (this.labelText) {
            const labelText = this.labelText.trim();
            if (labelText.length > 250) {
                callback('ラベルのテキストは250文字以内で入力してください。');
                return;
            }
            if (!this.labelColor) {
                callback('ラベルの色を選択してください。');
                return;
            }
            callback();
        }

        if (!this.labelText) {
            if (this.labelColor) {
                callback('ラベルのテキストを入力してください。');
                return;
            }
        }
        callback();
    }

    validator(): FormValidator<PeriodicContractFormModel> {
        return {
            validTerm: [
                {
                    required: true,
                    validator: (_rule, _value, callback) =>
                        this.validateValidTerm(callback),
                },
            ],
            schedule: [
                {
                    validator: (_rule, _value, callback) => {
                        if (!this.schedule.monday && !this.schedule.tuesday && !this.schedule.wednesday &&
                            !this.schedule.thursday && !this.schedule.friday && !this.schedule.saturday &&
                            !this.schedule.sunday) {
                            callback('曜日を選択してください。');
                            return;
                        }
                        callback();
                    }
                },
            ],
            description: [
                {
                    required: false,
                    validator: (_rule, _value, callback) => {
                        const description = _value?.trim() ?? '';
                        if (description.length > 2000) {
                            callback('2000文字以内で入力してください。');
                            return;
                        }
                        callback();
                    }
                },
            ],
            departureTime: [
                {
                    validator: (_rule, _value, callback) => this.validateTime(callback, this.departureTimeType, _value),
                },
            ],
            loadingTimeNote: [
                {
                    max: Const.MAX_LOADING_TIME_NOTE,
                    message: `積み時間は${ Const.MAX_LOADING_TIME_NOTE }文字以内で入力してください。`,
                },
            ],
            arrivalTime: [
                {
                    validator: (_rule, _value, callback) => this.validateTime(callback, this.arrivalTimeType, _value),
                },
            ],
            arrivalTimeType: [
                {
                    validator: (_rule, _value, callback) => this.validateSameDayDepartureArrivalTime(callback),
                },
            ],
            unloadingTimeNote: [
                {
                    max: Const.MAX_UNLOADING_TIME_NOTE,
                    message: `卸し時間は${ Const.MAX_UNLOADING_TIME_NOTE }文字以内で入力してください。`,
                },
            ],
            departureLocation: [
                {
                    required: true,
                    validator: (_rule, _value, callback) => this.validateLocation(callback, _value?.value),
                },
            ],
            arrivalLocation: [
                {
                    required: true,
                    validator: (_rule, _value, callback) => this.validateLocation(callback, _value?.value),
                },
            ],
            shape: [
                {
                    required: true,
                    message: '荷姿を選択してください。',
                },
            ],
            type: [
                {
                    required: true,
                    message: '荷種を入力してください。',
                },
                {
                    max: 250,
                    message: '荷種は250文字以内で入力してください。',
                },
            ],
            paletteCount: [
                {
                    pattern: /^[0-9]+$/,
                    message: 'パレット枚数（目安）は数字で入力してください。',
                },
                {
                    max: Const.MAX_PALETTE_COUNT_DIGITS,
                    message: `パレット枚数（目安）は最大${ Const.MAX_PALETTE_COUNT_DIGITS }桁で入力してください。`,
                },
            ],
            paletteSize: [
                {
                    required: false,
                    validator: (_rule, _value, callback) => this.validatePaletteSize(callback),
                }
            ],
            totalCount: [
                {
                    pattern: /^[0-9]+$/,
                    message: '荷物の個数（目安）は数字で入力してください。',
                },
                {
                    max: Const.MAX_BAGGAGE_COUNT_DIGITS,
                    message: `荷物の個数（目安）は最大${ Const.MAX_BAGGAGE_COUNT_DIGITS }桁で入力してください。`,
                },
            ],
            totalVolume: [
                {
                    pattern: /^[0-9]+$/,
                    message: '荷物の体積は数字で入力してください。',
                },
                {
                    max: Const.MAX_BAGGAGE_VOLUME_DIGITS,
                    message: `荷物の体積は最大${ Const.MAX_BAGGAGE_VOLUME_DIGITS }桁で入力してください。`,
                },
            ],
            totalWeight: [
                {
                    pattern: /^[0-9]+$/,
                    message: '総重量は数字で入力してください。',
                },
                {
                    max: Const.MAX_BAGGAGE_VOLUME_DIGITS,
                    message: `総重量は最大${ Const.MAX_BAGGAGE_VOLUME_DIGITS }桁で入力してください。`,
                },
            ],
            truckEquipment: [
                {
                    required: false,
                    validator(_rule, _value, callback) {
                        const truckEquipment = _value?.trim() ?? '';
                        if (truckEquipment.length > 250) {
                            callback('250文字以内で入力してください。');
                            return;
                        }
                        callback();
                    }
                },
            ],
            baggageFreight: [
                {
                    required: true,
                    validator: (_rule, _value, callback) => {
                        if (this.baggageFreight.isNegotiate) {
                            callback();
                            return;
                        }
                        if ((this.baggageFreight.value ?? 0) > 0) {
                            callback();
                            return;
                        }
                        callback('金額を入力するか、要相談をチェックしてください。');
                    }
                },
            ],
            baggageDescription: [
                {
                    required: false,
                    validator: (_rule, _value, callback) => {
                        const description = _value?.trim() ?? '';
                        if (description.length > 2000) {
                            callback('2000文字以内で入力してください。');
                            return;
                        }
                        callback();
                    }
                },
            ],
            shipperName: [
                {
                    required: false,
                    validator: (_rule, _value, callback) => {
                        const shipperName = _value?.trim() ?? '';
                        if (shipperName.length > 250) {
                            callback('荷主名は250文字以内で入力してください。');
                            return;
                        }
                        callback();
                    }
                },
            ],
            staffName: [
                {
                    required: true,
                    validator: (_rule, _value, callback) => {
                        const staffName = _value?.trim() ?? '';
                        if (!staffName) {
                            callback('担当者名を入力してください。');
                            return;
                        }

                        if (staffName.length > 250) {
                            callback('担当者名は250文字以内で入力してください。');
                            return;
                        }
                        callback();
                    }
                },
            ],
            circleId: [
                {
                    required: true,
                    validator: (_rule, _value, callback) => {
                        if (_value) {
                            callback();
                            return;
                        }
                        callback('部屋を選択してください。');
                    }
                },
            ],
            label: [
                {
                    required: false,
                    validator: (_rule, _value, callback) => this.validateLabel(callback),
                },
            ],
        };
    }

    private static ensureDayJs(value: dayjs.Dayjs | string): dayjs.Dayjs {
        return typeof value === 'string'
            ? DateUtil.parseDatetimeText(value)
            : value;
    }

    static of(periodicContract: PeriodicContract): PeriodicContractFormModel {
        const instance = new PeriodicContractFormModel();
        instance.validFrom = periodicContract.validFrom;
        instance.validTo = periodicContract.validTo;
        instance.schedule = periodicContract.schedule;
        instance.description = periodicContract.description;
        // 荷物情報
        const baggage = periodicContract.baggage;
        instance.circleId = baggage.circleId;
        instance.departureTime = baggage.departureTime;
        instance.departureTimeType = baggage.departureTimeType;
        instance.departureLocation = new TmpBaggageLocationValue({
            prefecture: baggage.departureLocation.prefecture,
            city: baggage.departureLocation.city,
            address: baggage.departureLocation.address,
        });
        instance.loadingTimeNote = baggage.loadingTimeNote;
        instance.arrivalDateType = baggage.arrivalDateType;
        instance.arrivalTime = baggage.arrivalTime;
        instance.arrivalTimeType = baggage.arrivalTimeType;
        instance.arrivalLocation = new TmpBaggageLocationValue({
            prefecture: baggage.arrivalLocation.prefecture,
            city: baggage.arrivalLocation.city,
            address: baggage.arrivalLocation.address,
        });
        instance.unloadingTimeNote = baggage.unloadingTimeNote;
        instance.category = baggage.category;
        instance.type = baggage.type;
        instance.shape = baggage.shape;
        instance.temperatureZone = baggage.temperatureZone;
        instance.paletteCount = baggage.paletteCount?.toString();
        instance.paletteHeight = baggage.paletteHeight?.toString();
        instance.paletteWidth = baggage.paletteWidth?.toString();
        instance.totalCount = baggage.totalCount?.toString();
        instance.totalVolume = baggage.totalVolume?.toString();
        instance.totalWeight = baggage.totalWeight?.toString();
        instance.loading = baggage.loading;
        instance.unloading = baggage.unloading;
        instance.truckWeight = baggage.truckWeight;
        instance.truckModel = baggage.truckModel;
        instance.truckHeight = baggage.truckHeight;
        instance.truckWidth = baggage.truckWidth;
        instance.largeTruckFlg = baggage.largeTruckFlg;
        instance.truckEquipment = baggage.truckEquipment;
        instance.share = baggage.share;
        instance.express = baggage.express;
        instance.freight = baggage.freight?.toString();
        instance.highwayFareFlg = baggage.highwayFareFlg;
        instance.staffName = baggage.staffName;
        instance.baggageDescription = baggage.description;
        instance.underNegotiation = baggage.underNegotiation;
        instance.negotiationType = baggage.negotiationType;
        instance.shipperName = baggage.shipperName;
        instance.labelText = baggage.labelText;
        instance.labelColor = baggage.labelColor;

        return instance;
    }
}

/**
 * 定期契約登録・更新APIフォーム
 */
export class PeriodicContractApiForm {
    validFrom: string;
    validTo: string;
    description: string;
    schedule: ScheduledWeekOfDay;
    baggage: ApiPeriodicContractBaggage;

    constructor(param: PeriodicContractFormModel) {
        this.validFrom = param.validFrom;
        this.validTo = param.validTo;
        this.description = param.description;
        this.schedule = param.schedule;
        this.baggage = new ApiPeriodicContractBaggage(param);
    }
}

export class ApiPeriodicContractBaggage {
    circleId: number;
    departureTime?: string;
    departureTimeType: PeriodicContractBaggageTimeTypeEnum;
    departurePref: { code?: string };
    departureCity: string;
    departureAddress?: string;
    loadingTimeNote?: string;
    arrivalDateType: ArrivalDataTypeEnum;
    arrivalTime?: string;
    arrivalTimeType: PeriodicContractBaggageTimeTypeEnum;
    arrivalPref: { code?: string };
    arrivalCity: string;
    arrivalAddress?: string;
    unloadingTimeNote?: string;
    category: { code?: BaggageCategoryEnumCode };
    type: string;
    shape?: Enum<BaggageShapeEnumCode>;
    temperatureZone: { code?: string };
    paletteCount?: string;
    paletteHeight?: string;
    paletteWidth?: string;
    totalCount?: string;
    totalVolume?: string;
    totalWeight?: string;
    loading?: Enum<BaggageHandlingTypeEnumCode>;
    unloading?: Enum<BaggageHandlingTypeEnumCode>;
    truckWeight: { code?: string };
    truckModel: { code?: string };
    truckHeight?: { code: string };
    truckWidth?: { code: string };
    largeTruckFlg?: boolean;
    truckEquipment?: string;
    share: boolean;
    express: boolean;
    freight?: string;
    highwayFareFlg: boolean;
    staffName: string;
    description?: string;
    underNegotiation: boolean;
    negotiationType: Enum<NegotiationTypeEnumCode>;
    shipperName?: string;
    labelText?: string;
    labelColor?: string;

    constructor(param: PeriodicContractFormModel) {
        this.circleId = Util.requireNotNull(param.circleId);
        this.departureTime = param.departureTime;
        this.departureTimeType = Util.requireNotNull(param.departureTimeType);
        this.departurePref = { code: param.departureLocation.prefecture?.code };
        this.departureCity = Util.requireNotNull(param.departureLocation.city);
        this.departureAddress = param.departureLocation.address;
        this.loadingTimeNote = param.loadingTimeNote;
        this.arrivalDateType = param.arrivalDateType;
        this.arrivalTime = param.arrivalTime;
        this.arrivalTimeType = param.arrivalTimeType;
        this.arrivalPref = { code: param.arrivalLocation.prefecture?.code };
        this.arrivalCity = Util.requireNotNull(param.arrivalLocation.city);
        this.arrivalAddress = param.arrivalLocation.address;
        this.unloadingTimeNote = param.unloadingTimeNote;
        this.category = param.category;
        this.type = param.type;
        this.shape = param.shape;
        this.temperatureZone = param.temperatureZone;
        this.paletteCount = param.paletteCount;
        this.paletteHeight = param.paletteHeight;
        this.paletteWidth = param.paletteWidth;
        this.totalCount = param.totalCount;
        this.totalVolume = param.totalVolume;
        this.totalWeight = param.totalWeight;
        this.loading = param.loading;
        this.unloading = param.unloading;
        this.truckWeight = param.truckWeight;
        this.truckModel = param.truckModel;
        this.truckHeight = param.truckHeight;
        this.truckWidth = param.truckWidth;
        this.largeTruckFlg = param.largeTruckFlg;
        this.truckEquipment = param.truckEquipment;
        this.share = param.share;
        this.express = param.express;
        this.freight = param.freight;
        this.highwayFareFlg = Util.requireNotNull(param.highwayFareFlg);
        this.staffName = param.staffName;
        this.description = param.baggageDescription;
        this.underNegotiation = param.underNegotiation;
        this.negotiationType = Util.requireNotNull(param.negotiationType);
        this.shipperName = param.shipperName;
        this.labelText = param.labelText;
        this.labelColor = param.labelColor;
    }
}

/**
 *  定期契約実行フォーム
 */
export class PeriodicContractExecuteForm {
    startDate: string;
    endDate: string;

    constructor(param: Partial<PeriodicContractExecuteForm> | null = null) {
        this.startDate = param?.startDate ?? '';
        this.endDate = param?.endDate ?? '';
    }
}

/**
 *  定期契約実行フォームモデル
 */
export class PeriodicContractExecuteFormModel
    extends PeriodicContractExecuteForm
    implements FormValidatable<PeriodicContractExecuteFormModel> {

    get validTerm(): [string, string] {
        return [this.startDate, this.endDate];
    }

    set validTerm(value: string[]) {
        this.startDate = value[0];
        this.endDate = value[1];
    }

    validateValidTerm(callback: (message?: string) => void): void {
        if (_.isEmpty(this.startDate) || _.isEmpty(this.endDate)) {
            callback('契約期間を選択してください。');
        }
        callback();
    }

    validator(): FormValidator<PeriodicContractExecuteFormModel> {
        return {
            validTerm: [
                {
                    required: true,
                    validator: (_rule, _value, callback) =>
                        this.validateValidTerm(callback),
                }
            ],
        };
    }
}
