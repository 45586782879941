import { ref } from 'vue';
import { Baggage } from '@/models/baggage';
import { useLoading } from '@/composables/helper/loading-helper';
import { truckApi } from '@/repository/api/internal/truck';
import { BaggageUtil } from '@/util';
import { companyApi } from '@/repository/api/internal/company';
import { CompanyProfile } from '@/models/company';
import _ from 'lodash';
import { TruckList } from '@/models/truck';
import { useCompanyMyProfile } from '@/composables/global/company-my-profile';

export type TruckRecommendationResult = TruckList['data'][number] & { company: CompanyProfile };

/**
 * 荷物に合うお勧め空車検索機能を提供します。
 */
export const useMyBaggageTruckRecommendation = () => {
    const { state: { loading, }, withLoading } = useLoading();
    const list = ref<TruckRecommendationResult[]>([]);
    const { state: { myProfile }, load: loadMyProfile } = useCompanyMyProfile();

    const companies = async (ids: number[]) => {
        const companies = ids.length > 0 ? await companyApi.listProfile({ id: ids }) : [];
        return _.keyBy(companies, each => each.id);
    };

    const search = (baggage: Baggage) => withLoading(async () => {
        if (!myProfile.value) await loadMyProfile();
        const form = BaggageUtil.toTruckSearchForm(baggage);
        form.circleId = baggage.circle?.id;
        const truckList = await truckApi.searchTruck(form);
        const companyIds = truckList.data.map(truck => truck.companyId);
        const companyMap = await companies(companyIds);

        list.value = truckList.data
            .map(truck => ({
                ...truck,
                company: companyMap[truck.companyId]
            }));
    });

    const clear = () => {
        list.value = [];
    };

    return {
        state: {
            loading,
            list,
        },
        search,
        clear,
    };
};
